export const environment = {
  production: false,
  authDomain: 'beta.novels-ai.com',
  API_URL_ADD_CHAPTER: 'https://add-chapter-i3rsqvn75a-lm.a.run.app/',
  API_URL_GENERATE_NEW_NOVEL: 'https://generate-audiobook-i3rsqvn75a-lm.a.run.app/',
  API_URL_REMOVE_NOVEL: 'https://delete-audiobook-i3rsqvn75a-lm.a.run.app/',
  API_URL_EXPORT_AUDIOBOOK: 'https://export-i3rsqvn75a-nw.a.run.app',
  API_URL_TEMPLATES: 'https://europe-central2-ai-audiobook.cloudfunctions.net/manageTemplate/',
  API_URL_GET_TEMPLATES: 'https://europe-central2-ai-audiobook.cloudfunctions.net/getTemplates/',
  API_URL_CREATE_VOICE: 'https://europe-central2-ai-audiobook.cloudfunctions.net/createVoice/',
  API_URL_SAVE_VOICE: 'https://europe-central2-ai-audiobook.cloudfunctions.net/saveVoice/',
  API_URL_LIST_VOICES: 'https://europe-central2-ai-audiobook.cloudfunctions.net/listVoices/',
  API_URL_EXPORT_AUDIOBOOK_VIDEO: 'https://europe-west2-ai-audiobook.cloudfunctions.net/exportAudiobookVideo/',
  
  STORIES_PER_PAGE: 5,
  NEW_USER_POINTS: 1,
  SUBSCRIPTION_PRICE_ID: 'price_1QK3rEBPvg43OlrWCaP4YQ0w',
  STRIPE_TEST_MODE: true,
  POINTS_PRICE_ID: 'price_1MvO0jBPvg43OlrWpKdsLptb',
};
